@import '~app-variables.scss';

.ctrComp {
  background: none !important;
  height: $ctr-layout-header-height;
  margin: 0 $ctr-gutter-size;
  border-bottom: 2px solid $ctr-border-color-base;

  &__breadcrumb {
    line-height: $ctr-layout-header-height;
    text-transform: uppercase;
    font-weight: $ctr-font-weight-bold;
    font-size: $ctr-font-size-md;
  }
}

