@import '~app-variables.scss';

.ctrComp {
  $size: 34px;


  &_mainMenu {
    position: sticky;
    top:0;
  }

  &:hover :global(.ant-layout-sider-trigger) {
    opacity: 1;
  }

  :global .ant-layout-sider-trigger {
    width: $size !important;
    height: $size !important;
    line-height: $size - 3px !important;
    border-radius: 50%;
    top: $ctr-layout-header-height;
    right: -$size/2;
    position: absolute !important;
    background-color: rgb(76, 154, 255);
    border: 2px solid $ctr-color-white;
    opacity: 0;
  }

  &_logo {
    height: 80px;

    :global img {
      background-position: center;
    }
  }
}
