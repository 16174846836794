@import '~app-variables.scss';

.dfoPad__none {
  padding: 0 !important;
}

.dfoPad__base {
  padding: $ctr-gutter-size !important;
}

.dfoPad__base--l {
  padding-left: $ctr-gutter-size !important;
}

.dfoPad__base--r {
  padding-right: $ctr-gutter-size !important;
}

.dfoPad__base--t {
  padding-top: $ctr-gutter-size !important;
}

.dfoPad__base--b {
  padding-bottom: $ctr-gutter-size !important;
}

.dfoPad__half {
  padding: $ctr-half-gutter-size !important;
}

.dfoPad__half--l {
  padding-left: $ctr-half-gutter-size !important;
}

.dfoPad__half--r {
  padding-right: $ctr-half-gutter-size !important;
}

.dfoPad__half--t {
  padding-top: $ctr-half-gutter-size !important;
}

.dfoPad__half--b {
  padding-bottom: $ctr-half-gutter-size !important;
}
.dfoPad__double {
  padding: $ctr-double-gutter-size !important;
}

.dfoPad__double--l {
  padding-left: $ctr-double-gutter-size !important;
}

.dfoPad__double--r {
  padding-right: $ctr-double-gutter-size !important;
}

.dfoPad__double--t {
  padding-top: $ctr-double-gutter-size !important;
}

.dfoPad__double--b {
  padding-bottom: $ctr-double-gutter-size !important;
}

//******************** MARGIN ********************//
.dfoMar__none {
  margin: 0 !important;
}
.dfoMar__base {
  margin: $ctr-gutter-size !important;
}

.dfoMar__base--l {
  margin-left: $ctr-gutter-size !important;
}

.dfoMar__base--r {
  margin-right: $ctr-gutter-size !important;
}

.dfoMar__base--t {
  margin-top: $ctr-gutter-size !important;
}

.dfoMar__base--b {
  margin-bottom: $ctr-gutter-size !important;
}

.dfoMar__half {
  margin: $ctr-half-gutter-size !important;
}

.dfoMar__half--l {
  margin-left: $ctr-half-gutter-size !important;
}

.dfoMar__half--r {
  margin-right: $ctr-half-gutter-size !important;
}

.dfoMar__half--t {
  margin-top: $ctr-half-gutter-size !important;
}

.dfoMar__half--b {
  margin-bottom: $ctr-half-gutter-size !important;
}

.dfoMar__double {
  margin: $ctr-double-gutter-size !important;
}

.dfoMar__double--l {
  margin-left: $ctr-double-gutter-size !important;
}

.dfoMar__double--r {
  margin-right: $ctr-double-gutter-size !important;
}

.dfoMar__double--t {
  margin-top: $ctr-double-gutter-size !important;
}

.dfoMar__double--b {
  margin-bottom: $ctr-double-gutter-size !important;
}
