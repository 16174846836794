@import '~app-variables.scss';

.ctrComp {
  background-color: $ctr-layout-header-background;
  position: fixed;
  bottom: 0;
  width: $ctr-layout-sidebar-width;
  padding: $ctr-gutter-size;

  &--mini {
    padding: $ctr-half-gutter-size;
    width: $ctr-layout-sidebar-collapsed-width;
  }

  &__content {
    background: hsla(0, 0%, 100%, .12);
    //margin: $ctr-gutter-size;
    padding: $ctr-gutter-size $ctr-half-gutter-size;

    width: 100%;
    //width: $ctr-layout-sidebar-width - 2*$ctr-gutter-size;

  }
}
