@import '~app-variables.scss';

.ctrComp {

  &__userItem {
    display: flex;

    * {
      margin-right: $ctr-half-gutter-size;
      flex-grow: 1;
    }

    &__del {
      width: 40px;
      text-align: right;
      margin: 0;
      line-height: 30px;
    }
  }
}
