@import '~app-variables.scss';

.ctrComp {
  background-color: green;

  :global .ant-menu {
    width: $ctr-layout-sidebar-width - $ctr-gutter-size*2 !important;
    border-right: none;
  }
}
