@import '~app-variables.scss';

.ctrComp {
  :global .name {
    line-height: 32px;
    color: $ctr-color-white;
  }

  &--mini {
    :global .name {
      display: none;
    }
  }
}
